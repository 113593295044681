import { createContext, useState } from "react";
import { Session, User } from "../types";

export const AppContext = createContext<{
  session?: Session;
  setSession: ReturnType<typeof useState<Session | undefined>>[1];
  logout: (
    /** A valid query string (omitting the leading `?`) that will be appended to the root of the landing page. */
    loggedOutQueryParams?: string,
  ) => void;
  updateUser: (user: User) => void;
}>({ setSession: () => {}, logout: () => {}, updateUser: () => {} });
