import { Result, err, ok } from "neverthrow";
import { ErrorMessages } from "./utils/errorMessages";

export const parseEmailVerificationParams = (): Result<
  { code: string },
  string
> => {
  const params = new URLSearchParams(location.search);
  const code = params.get("code");

  if (!code) {
    return err(ErrorMessages.emailVerification.INVALID_URI);
  }

  return ok({ code });
};

export const parseResetPasswordParams = (): Result<
  Record<"code" | "email", string>,
  string
> => {
  const params = new URLSearchParams(location.search);
  const code = params.get("code");
  const email = params.get("email");

  if (!code || !email) {
    return err(ErrorMessages.resetPassword.INVALID_URI);
  }

  return ok({ code, email });
};
