import { AccountAppRoutes } from "../utils/constants";
import { Navigate } from "react-router-dom";
import { PropsWithChildren } from "react";
import { useAppContext } from "../hooks/useAppContext";

export const SingleFactorProtectedRoute = ({ children }: PropsWithChildren) => {
  const { session } = useAppContext();

  if (!session) {
    return <Navigate to={AccountAppRoutes.ROOT} />;
  }

  return children;
};
