import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { useIntersectionObserver } from "@uidotdev/usehooks";

export const Card = ({
  children,
  className,
  "data-testid": dataTestId,
}: {
  children: ReactNode;
  className?: string;
  ["data-testid"]?: string;
}) => {
  const [cardRef, entry] = useIntersectionObserver({ threshold: 1 });

  return (
    <div
      ref={cardRef}
      className={twMerge(
        "rounded-t-3xl bg-white shadow-2xl md:rounded-3xl dark:bg-neutral-900",
        entry?.isIntersecting && "rounded-b-3xl",
        className,
      )}
      data-testid={dataTestId}
    >
      <section className="p-4 md:p-8">{children}</section>
    </div>
  );
};
