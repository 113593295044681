import * as Factory from "factory.ts";
import {
  InstrumentKind,
  DepositAddressInstrumentFragment,
} from "../generated/sdk";
import { nanoid } from "nanoid";

export const depositAddressInstrumentFactory =
  Factory.Sync.makeFactory<DepositAddressInstrumentFragment>({
    __typename: "DepositAddressInstrument",
    kind: InstrumentKind.DEPOSIT_ADDRESS,
    id: Factory.each(() => `w_${nanoid(8)}`),
    networkNamespace: "eip155:1",
    address: "0x1230000000000000000000000000000000000000",
    createdAt: new Date(2023, 10, 11).toISOString(),
    asset: {
      network: "eip155:11155111",
      address: "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238",
      symbol: "USDC",
      decimals: 6,
    },
  });
