import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { Button, Logo, Title, Text } from "@tigris/mesokit";
import { parseEmailVerificationParams } from "../parseParams";
import {
  ResendEmailVerificationError,
  resolveResendEmailVerification,
  resolveVerifyEmail,
  VerifyEmailError,
} from "../api";

const initializingContentAnimationVariants: Variants = {
  initial: { opacity: 0, scale: 0.8 },
  animate: { opacity: 0.5, scale: 1 },
  exit: { opacity: 0, scale: 0.8 },
};

const contentAnimationVariants: Variants = {
  initial: { opacity: 0, scale: 0.8 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.8 },
};

type CardContent = {
  title: string;
  body: ReactElement;
  buttonText?: string;
};

const templates: Record<
  | "error"
  | "terminalError"
  | "verificationSuccess"
  | "resendSuccess"
  | "expired",
  CardContent
> = {
  error: {
    title: "Something went wrong",
    body: (
      <>
        Something went wrong on our end, please try again. If this persists
        contact <a href="support@meso.network">support@meso.network</a>.
      </>
    ),
    buttonText: "Resend Verification",
  },
  terminalError: {
    title: "Something went wrong",
    body: (
      <>
        Something went wrong on our end, please try again. If this persists
        contact <a href="support@meso.network">support@meso.network</a>.
      </>
    ),
  },
  verificationSuccess: {
    title: "Email Verified",
    body: (
      <>
        Thank you for verifying your email address. This will ensure you receive
        all necessary communication, including transfer receipts and security
        notifications. <br /> You can close this window or click the link to log
        in to your Meso account, where you can update linked wallets and payment
        methods and view your transfer history.
      </>
    ),
    buttonText: "Log in to your Meso account",
  },
  resendSuccess: {
    title: "Verification Successfully Re-sent",
    body: <>We&apos;ve sent you a new verification link. Check your email.</>,
  },
  expired: {
    title: "Verification Expired",
    body: (
      <>
        Your verification link has expired. Click below so we can send a new
        one.
      </>
    ),
    buttonText: "Resend Verification",
  },
};

export const EmailVerification = () => {
  const params = useMemo(() => parseEmailVerificationParams(), []);
  const [content, setContent] = useState<CardContent | undefined>(() => {
    if (params.isErr()) {
      return undefined;
    }
  });
  const [isLoading, setIsLoading] = useState<boolean>(() => params.isOk());
  const [buttonAction, setButtonAction] =
    useState<
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    >();

  const resend = useCallback(async () => {
    if (params.isErr()) return;

    setIsLoading(true);

    const resendEmailVerificationResult = await resolveResendEmailVerification({
      input: { code: params.value.code },
    });

    if (resendEmailVerificationResult.isErr()) {
      setIsLoading(false);

      if (
        resendEmailVerificationResult.error ===
        ResendEmailVerificationError.UNABLE_TO_RESEND
      ) {
        setContent(templates.error);
      } else {
        setContent(templates.terminalError);
      }

      return;
    }

    setIsLoading(false);
    setContent(templates.resendSuccess);
  }, [params]);

  useEffect(() => {
    (async () => {
      if (params.isErr()) {
        setContent(templates.error);
        setButtonAction(() => resend);
        setIsLoading(false);
        return;
      }

      const verifyEmailResult = await resolveVerifyEmail({
        input: { code: params.value.code },
      });

      if (verifyEmailResult.isErr()) {
        switch (verifyEmailResult.error) {
          case VerifyEmailError.GENERIC:
            setContent(templates.error);
            setButtonAction(() => resend);
            setIsLoading(false);
            break;
          case VerifyEmailError.VERIFICATION_FAILED:
            setContent(templates.expired);
            setButtonAction(() => resend);
            setIsLoading(false);
            break;
          case VerifyEmailError.INVALID_CODE:
            setContent(templates.expired);
            setButtonAction(() => resend);
            setIsLoading(false);
        }

        return;
      }

      setContent(templates.verificationSuccess);
      setButtonAction(
        () => () => (window.location.href = "https://account.meso.network"),
      );
      setIsLoading(false);
    })();
  }, [params, resend]);

  const { title, body, buttonText } = content || {};

  return (
    <AnimatePresence mode="wait">
      {isLoading ? (
        <motion.div
          data-testid="verification-loading"
          key="verification-loading"
          initial="initial"
          animate="animate"
          exit="exit"
          variants={initializingContentAnimationVariants}
          className="flex flex-col items-center justify-center gap-4"
        >
          <Logo showText={false} size="md" className="mb-2" />
          <Text className="animate-pulse text-sm font-bold">Loading...</Text>
        </motion.div>
      ) : (
        <motion.div
          data-testid="verification-content"
          key="verification-content"
          initial="initial"
          animate="animate"
          exit="exit"
          variants={contentAnimationVariants}
          className="mx-auto flex max-w-[324px] flex-col items-center justify-center gap-4 md:max-w-none"
        >
          <section className="flex flex-col items-center gap-1">
            <Logo showText={false} size="md" className="mb-2" />
            <Title.Medium
              data-testid="verification-content:title"
              className="font-bold"
            >
              {title}
            </Title.Medium>
            <Text className="text-center">{body}</Text>
          </section>
          {buttonText && <Button onClick={buttonAction}>{buttonText}</Button>}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
