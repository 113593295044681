import {
  Button,
  Input,
  PasswordStrength,
  PasswordValidity,
} from "@tigris/mesokit";
import {
  useCallback,
  FormEventHandler,
  ChangeEventHandler,
  useEffect,
  useState,
  useRef,
} from "react";
import { useThrottle } from "@uidotdev/usehooks";
import measurePasswordStrength from "zxcvbn";
import { resolveResetPassword } from "../../api";
import { ErrorMessages } from "../../utils/errorMessages";
import { toast } from "sonner";
import {
  MIN_PASSWORD_LENGTH,
  Posthog,
  TelemetryEvents,
  defaultFormField,
  passwordSchema,
} from "@tigris/common";
import { FormField } from "../../types";

type FormState = {
  isValid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  fields: Record<"password", FormField>;
};

const defaultFormState: FormState = {
  isValid: false,
  isDirty: false,
  isTouched: false,
  fields: { password: defaultFormField("") },
};

const FORM_ID = "ResetPassword:EnterPassword";
const TOAST_ID = FORM_ID;

export const EnterPassword = ({
  onSuccess,
  onError,
  emailAddress,
}: {
  onSuccess: () => void;
  onError: (message: string) => void;
  emailAddress: string;
}) => {
  const [formState, setFormState] = useState<FormState>(defaultFormState);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const throttled = useThrottle(formState.fields, 500);
  const [isLoading, setIsLoading] = useState(false);
  const passwordInput = useRef<HTMLInputElement>(null);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();

      setIsLoading(true);

      const updatePasswordResult = await resolveResetPassword({
        input: { password: formState.fields.password.value },
      });

      if (updatePasswordResult.isErr()) {
        onError(updatePasswordResult.error);
        setIsLoading(false);
        setTimeout(() => {
          passwordInput.current?.focus();
        }, 10);
        return;
      }

      onSuccess();
    },
    [formState.fields.password.value, onError, onSuccess],
  );

  const handlePasswordChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >((event) => {
    const newValue = event.target.value;

    setFormState((previousState) => {
      const isDirty =
        previousState.fields.password.isTouched ||
        newValue !== previousState.fields.password.value;

      const result = passwordSchema.safeParse(newValue);
      const isValid = result.success;
      if (!result.success) {
        const errorCodes = result.error.issues.map((issue) => issue.code);

        if (errorCodes.includes("too_big")) {
          toast.error(ErrorMessages.resetPassword.TOO_BIG, { id: TOAST_ID });
        }
      } else {
        toast.dismiss(TOAST_ID);
      }

      if (!isValid) {
        Posthog.capture(TelemetryEvents.formInputInvalid, {
          formId: FORM_ID,
          inputId: "password",
        });
      }

      return {
        ...previousState,
        isDirty: previousState.isDirty || isDirty,
        isValid:
          isValid &&
          Object.entries(previousState.fields)
            .filter(([key, _]) => key !== "password")
            .every(([_, { isValid }]) => isValid),
        fields: {
          ...previousState.fields,
          password: {
            ...previousState.fields.password,
            value: newValue,
            isValid,
            isDirty,
          },
        },
      };
    });
  }, []);

  const handleBlur = useCallback(() => {
    setFormState((previousState) => ({
      ...previousState,
      isTouched: true,
      isValid: Object.values(previousState.fields).every(
        (field) => field.isValid,
      ),
      isDirty: Object.values(previousState.fields).every(
        (field) => field.isDirty,
      ),
      fields: {
        password: {
          ...previousState.fields.password,
          isTouched: true,
        },
      },
    }));
  }, []);

  useEffect(() => {
    const passwordStrengthResult = measurePasswordStrength(
      throttled.password.value,
    );
    setPasswordStrength(passwordStrengthResult.score);
  }, [throttled.password.value]);

  const renderFieldAsValid = useCallback(
    (fieldKey: keyof FormState["fields"]): boolean => {
      const field = formState.fields[fieldKey];
      if (!field.isTouched || field.isValid) {
        return true;
      }

      if (field.isTouched && field.isDirty) {
        return field.isValid;
      }

      return true;
    },
    [formState.fields],
  );

  useEffect(() => {
    setTimeout(() => {
      passwordInput.current?.focus();
    }, 10);
  }, []);

  return (
    <form
      id={FORM_ID}
      name={FORM_ID}
      data-testid={FORM_ID}
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1">
          {/* For password managers */}
          <input
            className="hidden"
            name="username"
            autoComplete="username"
            id="username"
            value={emailAddress}
            readOnly={true}
          />
          <Input
            name="password"
            id="password"
            type="password"
            value={formState.fields.password.value}
            data-testid={`${FORM_ID}:password`}
            isValid={renderFieldAsValid("password")}
            onChange={handlePasswordChange}
            onBlur={handleBlur}
            placeholder="New Password"
            autoComplete="new-password"
            ref={passwordInput}
            disabled={isLoading}
          />
          <div className="flex gap-2">
            <PasswordValidity
              isValid={formState.fields.password.isValid}
              minPasswordLength={MIN_PASSWORD_LENGTH}
            />
            <PasswordStrength
              strength={passwordStrength}
              validPasswordLength={formState.fields.password.isValid}
            />
          </div>
        </div>
        <Button
          disabled={isLoading || !formState.isValid}
          type="submit"
          isLoading={isLoading}
        >
          Change Password
        </Button>
      </div>
    </form>
  );
};
