import * as Factory from "factory.ts";
import { InstrumentKind, WalletInstrumentFragment } from "../generated/sdk";
import { nanoid } from "nanoid";

export const walletInstrumentFactory =
  Factory.Sync.makeFactory<WalletInstrumentFragment>({
    __typename: "WalletInstrument",
    kind: InstrumentKind.WALLET,
    id: Factory.each(() => `w_${nanoid(8)}`),
    networkNamespace: "eip155:1",
    address: "0x0000000000000000000000000000000000000000",
    createdAt: new Date(2023, 10, 11).toISOString(),
    verified: true,
  });
