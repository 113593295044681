import { Logo, Title, Text } from "@tigris/mesokit";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const animateIn = {
  initial: {
    scale: 0.8,
    opacity: 0,
    y: 60,
  },
  animate: {
    scale: 1,
    y: 0,
    opacity: 1,
  },
};

const NotFound = () => {
  const { pathname } = useLocation();

  return (
    <section className="flex h-screen w-screen flex-col items-center justify-center">
      <motion.div
        initial={{ filter: "blur(10px)", opacity: 0 }}
        animate={{ filter: "blur(0px)", opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <Title.Large className="font-bold opacity-80 dark:opacity-60">
          We can’t find that page
        </Title.Large>

        <div
          className="flex items-center gap-4 text-[8rem] font-black leading-none text-neutral-800 dark:text-white"
          style={{
            filter:
              "drop-shadow(0px 1px 44px rgba(255, 255, 255, 0.06)) drop-shadow(0px 1px 16px rgba(255, 255, 255, 0.1))",
          }}
        >
          <AnimatePresence>
            <motion.div
              key="four-one"
              variants={animateIn}
              initial="initial"
              animate="animate"
            >
              4
            </motion.div>
            <motion.div
              key="logo"
              variants={animateIn}
              initial="initial"
              animate="animate"
              transition={{ delay: 0.25 }}
            >
              <Logo size="xl" showText={false} />
            </motion.div>
            <motion.div
              key="four-two"
              variants={animateIn}
              initial="initial"
              animate="animate"
              transition={{ delay: 0.5 }}
            >
              4
            </motion.div>
          </AnimatePresence>
        </div>
        <Text className="font-mono font-bold opacity-60">
          <span className="rounded-md border bg-neutral-50 p-1 shadow-md dark:border-neutral-600 dark:bg-neutral-700">
            {pathname}
          </span>{" "}
          Not Found
        </Text>
      </motion.div>
    </section>
  );
};

export default NotFound;
