import * as Factory from "factory.ts";
import { UserFragment } from "../generated/sdk";
import { fiatInstrumentFactory } from "./FiatInstrument";
import { walletInstrumentFactory } from "./WalletInstrument";
import { depositAddressInstrumentFactory } from "./DepositAddressInstrument";
import { nanoid } from "nanoid";

export const userFactory = Factory.Sync.makeFactory<UserFragment>({
  __typename: "User",
  status: "new",
  id: Factory.each(() => `u_${nanoid(8)}`),
  email: "john.smith@meso.network",
  theme: "default",
  fiatInstruments: {
    __typename: "FiatInstruments",
    collection: [fiatInstrumentFactory.build()],
  },
  walletInstruments: {
    __typename: "WalletInstruments",
    collection: [walletInstrumentFactory.build()],
  },
  depositAddressInstruments: {
    __typename: "DepositAddressInstruments",
    collection: [depositAddressInstrumentFactory.build()],
  },
});

export const userWithoutDebitCardsFactory = userFactory.extend({
  fiatInstruments: {
    __typename: "FiatInstruments",
    collection: [],
  },
});
