import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountAppRoutes } from "../../utils/constants";
import { userFactory } from "../../factories/User";
import { twMerge } from "tailwind-merge";
import { AppContext } from "../../contexts/AppContext";

const AUTO_LOGIN_KEY = "autoLogin";

export const DevOnlyControls = () => {
  const navigate = useNavigate();
  const { setSession } = useContext(AppContext);
  const [autoLoginEnabled, setAutoLoginEnabled] = useState<boolean>(() => {
    const enabled = sessionStorage.getItem(AUTO_LOGIN_KEY);

    return enabled === "true";
  });

  const toggleAutoLogin = useCallback(() => {
    if (autoLoginEnabled) {
      sessionStorage.removeItem(AUTO_LOGIN_KEY);
      setAutoLoginEnabled(false);
    } else {
      sessionStorage.setItem(AUTO_LOGIN_KEY, "true");
      setAutoLoginEnabled(true);
      setSession({
        id: "stub",
        token: "stub",
        riskSessionKey: "stub",
        user: userFactory.build(),
      });
      navigate(AccountAppRoutes.TRANSFERS);
    }
  }, [autoLoginEnabled, navigate, setSession]);

  if (!import.meta.env.DEV) return;

  return (
    <div className="fixed bottom-10 right-10 rounded-xl bg-black/[.6] p-3">
      <h1 className="text-sm font-bold">Dev Only</h1>
      <hr className="my-2 opacity-20" />
      <button
        className={twMerge(
          "rounded-ts-card transition-al px-4 py-2 text-sm",
          autoLoginEnabled && "bg-neutral-800",
          !autoLoginEnabled && "bg-neutral-800",
        )}
        onClick={toggleAutoLogin}
      >
        <div className="flex items-center gap-2 align-middle">
          <div
            className={twMerge(
              "h-[10px] w-[10px] rounded-full",
              autoLoginEnabled ? "bg-green-500" : "bg-red-500",
            )}
          />
          {autoLoginEnabled ? "Disable Auto Login" : "Enable Auto Login"}
        </div>
      </button>
    </div>
  );
};
