import { AnimatePresence } from "framer-motion";
import { MesoToaster } from "@tigris/mesokit";
import { cloneElement } from "react";
import { useAppContext } from "../hooks/useAppContext";
import { useLocation, useOutlet } from "react-router-dom";
import NavBar from "../components/NavBar";
import { twMerge } from "tailwind-merge";
import { DevOnlyControls } from "../components/dev/DevOnlyControls";

export const AppLayout = () => {
  const { pathname } = useLocation();
  const { session } = useAppContext();
  const outlet = useOutlet();
  const protectedRoute = !!(session && session.user);

  return (
    <div className="h-full">
      <div
        className={twMerge(
          "mx-auto h-full max-w-screen-md",
          protectedRoute && "pt-20 lg:pt-24",
          !protectedRoute && "flex w-96 items-center justify-center",
        )}
      >
        {protectedRoute && <NavBar />}
        <AnimatePresence mode="wait">
          {outlet && cloneElement(outlet, { key: pathname })}
        </AnimatePresence>
      </div>
      <MesoToaster />
      <DevOnlyControls />
    </div>
  );
};
