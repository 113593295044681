import {
  BlockchainExplorerButton,
  CardHero,
  CobrandingLogo,
  CopyToClipboard,
  FiatInstrument,
  LineItems,
  Text,
  ThemeName,
  WalletInstrument,
  spring,
  getFiatDisplayValue,
  getFiatType,
} from "@tigris/mesokit";
import {
  DepositAddressInstrument,
  FiatInstrumentFragment,
  TransferFragment,
  TransferKind,
  TransferStatus,
  WalletInstrumentFragment,
} from "../generated/sdk";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Network } from "../types";
import { TransferKindBadge } from "./TransferKindBadge";
import { formatDistance } from "date-fns";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useAppContext } from "../hooks/useAppContext";
import { useState } from "react";
import {
  cryptoAssetFromExchangeRate,
  networkFromExchangeRate,
} from "@tigris/common";
import { twMerge } from "tailwind-merge";

const idVariants: Variants = {
  initial: { opacity: 0, y: 48 },
  animate: {
    opacity: 1,
    y: 0,
    transition: { ...spring, delay: 0.25 },
  },
  exit: { opacity: 0, y: 48, transition: spring },
};

export const TransferDetail = ({
  transfer,
  fiatInstrument,
  walletInstrument,
  depositAddressInstrument,
}: {
  transfer: TransferFragment;
  fiatInstrument?: FiatInstrumentFragment;
  walletInstrument?: WalletInstrumentFragment;
  depositAddressInstrument?: DepositAddressInstrument;
}) => {
  const { session } = useAppContext();
  const theme = (session?.user?.theme ?? "default") as ThemeName;
  const asset = cryptoAssetFromExchangeRate(transfer.exchangeRate);
  const network = networkFromExchangeRate(transfer.exchangeRate);

  const isCashIn = transfer.kind == TransferKind.CASH_IN;
  const fiatInstrumentEl = (
    <FiatInstrument
      display={getFiatDisplayValue(fiatInstrument)}
      cardScheme={getFiatType(fiatInstrument)}
    />
  );
  const walletInstrumentEl = (
    <WalletInstrument
      network={network}
      walletAddress={walletInstrument?.address ?? ""}
    />
  );
  const sourceLabelText = isCashIn
    ? "Bank Transfer"
    : `Send ${transfer.sourceTotal.currency}`;
  const sourceDetail = isCashIn ? fiatInstrumentEl : walletInstrumentEl;

  const destinationLabelText = isCashIn ? "Transfer to Wallet" : "USD to Bank";
  const destinationDetail = isCashIn ? walletInstrumentEl : fiatInstrumentEl;
  const total = isCashIn
    ? transfer.sourceTotal.amount
    : transfer.destination.amount;

  return (
    <motion.div
      data-testid="TransferDetail"
      className={twMerge(
        "sm:rounded-ts-card flex h-screen w-screen origin-top flex-col overflow-auto bg-white text-neutral-800 shadow-2xl sm:h-auto sm:w-96 sm:overflow-hidden dark:bg-neutral-900",
        `theme-${theme}`,
      )}
      variants={idVariants}
      layout
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ delay: 0.2 }}
    >
      <div className="relative">
        <CobrandingLogo
          className="absolute top-[20px] z-10 h-6 w-full"
          displayName={transfer.partner.displayName}
          logoUri={transfer.partner.logoUri}
        />

        <CardHero
          theme={theme}
          asset={asset}
          amount={transfer.destination.amount}
          network={network}
          walletAddress={walletInstrument?.address ?? ""}
          fiatInstrument={fiatInstrument}
          depositAddress={depositAddressInstrument?.address}
          transferKind={transfer.kind}
          authenticated={!!session?.user?.id}
          canShowPopover={false}
        />
        <TransferKindBadge
          kind={transfer.kind}
          className="absolute right-4 top-4 h-6"
        />
      </div>
      <div className="flex flex-col gap-4 py-4 text-neutral-800 dark:text-white">
        <div
          className="flex flex-col gap-4 px-4"
          data-testid="TransferTimeline"
        >
          <Text className="text-sm font-semibold capitalize">
            Transfer Timeline
          </Text>
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-0">
              <div className="flex items-center gap-1">
                {isCashIn && transfer.status === TransferStatus.DECLINED ? (
                  <FontAwesomeIcon
                    icon={icon({ name: "exclamation-circle" })}
                    className="fa-solid text-[#EC4848]"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={icon({ name: "circle-check" })}
                    className="text-highlight"
                  />
                )}
                <Text className="font-medium">{sourceLabelText}</Text>
              </div>
              <Text className="pl-5 text-xs opacity-60">{sourceDetail}</Text>
            </div>
            <Text className="text-xs opacity-60">
              {[
                formatDistance(new Date(transfer.createdAt), Date.now(), {
                  addSuffix: true,
                }),
              ]
                .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
                .join("")}
            </Text>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center gap-1">
                {(transfer.status === TransferStatus.EXECUTING ||
                  transfer.status === TransferStatus.APPROVED) && (
                  <FontAwesomeIcon
                    icon={icon({ name: "loader" })}
                    className="fa-spin"
                  />
                )}
                {transfer.status === TransferStatus.COMPLETE && (
                  <FontAwesomeIcon
                    icon={icon({ name: "circle-check" })}
                    className="fa-solid text-highlight"
                  />
                )}
                {transfer.status === TransferStatus.DECLINED && (
                  <FontAwesomeIcon
                    icon={icon({ name: "exclamation-circle" })}
                    className="fa-solid text-[#EC4848]"
                  />
                )}
                <Text>{destinationLabelText}</Text>
              </div>
              <Text className="pl-5 text-xs opacity-60">
                {destinationDetail}
              </Text>
            </div>

            <Text className="text-xs opacity-60">
              {(transfer.status === TransferStatus.COMPLETE ||
                transfer.status === TransferStatus.DECLINED) &&
                [
                  formatDistance(new Date(transfer.updatedAt), Date.now(), {
                    addSuffix: true,
                  }),
                ]
                  .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
                  .join("")}
            </Text>
          </div>
        </div>

        <hr className="my-2 h-px border-0 bg-neutral-100 dark:bg-neutral-700" />

        <div className="flex flex-col gap-4 px-4">
          <Text className="text-sm font-semibold capitalize">
            Transfer Total
          </Text>
          <LineItems
            exchangeRate={transfer.exchangeRate}
            mesoFeeAmount={transfer.mesoFee.amount}
            networkFeeAmount={isCashIn ? transfer.networkFee.amount : undefined}
            networkFeeWaived={isCashIn && transfer.networkFeeWaived}
            subtotalAmount={transfer.sourceSubtotal.amount}
            totalAmount={total}
            transferKind={transfer.kind}
          />
        </div>

        <hr className="my-2 h-px border-0 bg-neutral-100 dark:bg-neutral-700" />

        <Details transfer={transfer} network={network} />
      </div>
    </motion.div>
  );
};

const Details = ({
  transfer,
  network,
}: {
  transfer: TransferFragment;
  network: Network;
}) => {
  const [isVisible, setVisible] = useState(false);

  return (
    <div className="px-4">
      <div
        className="flex cursor-pointer items-center justify-between rounded-full border p-2 px-4 transition-colors dark:border-neutral-600/40 hover:dark:bg-neutral-600/20"
        onClick={() => setVisible(!isVisible)}
      >
        <Text className="font-semibold">Additional Information</Text>
        <motion.div
          animate={{ rotate: isVisible ? 90 : 0 }}
          style={{ originX: 0.5, originY: 0.5 }}
        >
          <FontAwesomeIcon icon={icon({ name: "chevron-right" })} />
        </motion.div>
      </div>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ height: "0" }}
            animate={{ height: "auto" }}
            exit={{ height: "0" }}
            className="overflow-hidden"
          >
            <div className="mb-4 mt-4 flex flex-col gap-6">
              <div className="flex flex-col rounded-2xl">
                <div className="flex justify-between">
                  <Text className="text-sm font-bold opacity-60">
                    Meso Transfer ID
                  </Text>
                  <CopyToClipboard
                    text={transfer.id}
                    size="sm"
                    className="opacity-60"
                  />
                </div>
                <Text className="font-mono text-sm">{transfer.id}</Text>
              </div>
              <div className="flex flex-col gap-1 rounded-2xl">
                <div className="flex justify-between">
                  <Text className="text-sm font-bold opacity-60">
                    Blockchain Transaction Hash
                  </Text>
                  <CopyToClipboard
                    text={transfer.networkTransactionId}
                    size="sm"
                    className="opacity-60"
                  />
                </div>
                <Text className="font-mono text-sm">
                  {transfer.networkTransactionId}
                </Text>
              </div>
            </div>
            <div className="flex items-center justify-end">
              <div className="flex-1">
                <BlockchainExplorerButton
                  env={import.meta.env.VITE_TIGRIS_ENV}
                  network={network}
                  networkTransactionId={transfer.networkTransactionId}
                />
              </div>
              <div
                onClick={() => window.open("https://support.meso.network")}
                className="cursor-pointer"
              >
                <FontAwesomeIcon
                  icon={icon({ name: "circle-question" })}
                  className="opacity-60 transition-opacity hover:opacity-100"
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
