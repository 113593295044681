import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

export type NavItem = {
  label: string;
  href: string;
};

export const NavItem: React.FC<{ item: NavItem }> = ({ item }) => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setIsActive(pathname === item.href);
  }, [isActive, item.href, pathname]);

  return (
    <div
      onClick={() => navigate(item.href)}
      className="relative h-full w-full cursor-pointer"
      key={item.label}
    >
      <div
        className={`relative flex h-full items-center px-8 text-sm font-semibold capitalize text-black transition-opacity delay-200 dark:text-white ${
          isActive ? "opacity-100" : "opacity-50"
        }`}
      >
        <span className="relative z-10">{item.label}</span>
        {isActive && (
          <motion.div
            layoutId="pill"
            key={item.label}
            className="absolute left-0 z-0 h-full w-full rounded-full bg-neutral-900/10 shadow-sm dark:bg-white/10"
          />
        )}
      </div>
    </div>
  );
};
