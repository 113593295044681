import React from "react";
import { AccountAppRoutes } from "../utils/constants";
import { Disclosure } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { Logo } from "@tigris/mesokit";
import { LogoutPill } from "./LogoutPill";
import { NavItem } from "./NavItem";
import { AnimatePresence, motion } from "framer-motion";
import { useAppContext } from "../hooks/useAppContext";
import { twMerge } from "tailwind-merge";

const nav: NavItem[] = [
  { label: "Transfers", href: AccountAppRoutes.TRANSFERS },
  { label: "Settings", href: AccountAppRoutes.PROFILE_SETTINGS },
];

const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const { session } = useAppContext();
  const userIsActive = session?.user?.status === "active";

  return (
    <Disclosure
      as="nav"
      data-testid="NavBar"
      className={`fixed left-0 right-0 top-0 z-20 w-full`}
    >
      {({ open }) => {
        return userIsActive ? (
          <>
            <div
              className={`mx-auto max-w-7xl px-2 pt-4 transition sm:px-4 lg:px-8 ${
                open
                  ? "bg-white/80 backdrop-blur-sm lg:bg-transparent dark:bg-neutral-800/80"
                  : ""
              }`}
            >
              <div className="relative flex h-16 items-center justify-between p-2">
                <div className="flex flex-shrink-0 items-center">
                  <div
                    className="cursor-pointer"
                    onClick={() => navigate(AccountAppRoutes.TRANSFERS)}
                  >
                    <Logo showText />
                  </div>
                </div>
                <div className="shadow-glass absolute left-1/2 z-50 hidden h-12 translate-x-[calc(-50%)] items-center justify-center rounded-full bg-gradient-to-b from-white/10 to-white/5 p-1 backdrop-blur-lg lg:flex lg:backdrop-blur-md">
                  {nav.map((item) => (
                    <NavItem key={item.label} item={item} />
                  ))}
                </div>
                <div className={twMerge("hidden lg:inline-flex")}>
                  <LogoutPill />
                </div>
                <div className="flex items-center lg:hidden">
                  {open && (
                    <motion.div
                      className="mr-2 lg:hidden"
                      initial={{ opacity: 0, x: 48 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 48 }}
                    >
                      <LogoutPill />
                    </motion.div>
                  )}
                  {/* Mobile menu button */}
                  <Disclosure.Button className="focus:ring-primary z-10 inline-flex items-center justify-center rounded-md p-2 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <i className="fa-solid fa-close h-4 w-4" />
                    ) : (
                      <i className="fa-solid fa-bars h-4 w-4" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="relative z-10 lg:hidden">
              <AnimatePresence>
                {open && (
                  <motion.div
                    key="MobileMenu"
                    initial={{ opacity: 0, y: -48 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    className="h-screen space-y-4 bg-white/80 p-4 lg:bg-transparent dark:bg-neutral-800/80"
                  >
                    {nav.map((item) => (
                      <Disclosure.Button
                        as="a"
                        key={item.label}
                        onClick={() => navigate(item.href)}
                        className="block rounded-lg py-2 pr-4 text-base font-bold capitalize text-neutral-800 hover:bg-white/10 dark:text-white"
                      >
                        {item.label}
                      </Disclosure.Button>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
              <div className="absolute left-0 top-0 z-[-1] h-full w-full backdrop-blur-sm lg:hidden" />
            </Disclosure.Panel>
          </>
        ) : (
          <>
            <div
              className={
                "mx-auto max-w-7xl px-2 pt-4 transition sm:px-4 lg:px-8"
              }
            >
              <div className="relative flex h-16 items-center justify-between p-2">
                <div className="flex flex-shrink-0 items-center">
                  <div
                    className="cursor-pointer"
                    onClick={() => navigate(AccountAppRoutes.PROFILE_SETTINGS)}
                  >
                    <Logo showText />
                  </div>
                </div>
                <div className="inline-flex">
                  <LogoutPill />
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Disclosure>
  );
};

export default NavBar;
