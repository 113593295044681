import "./style.css";
import NotFound from "./components/NotFound";
import React from "react";
import ReactDOM from "react-dom/client";
import { AccountAppRoutes } from "./utils/constants";
import { AppContextProvider } from "./contexts/AppContextProvider";
import { AppLayout } from "./layouts/AppLayout";
import { EmailVerification } from "./components/EmailVerification";
import { Login2FA } from "./components/Login2FA";
import { LoginEmailPassword } from "./components/LoginEmailPassword";
import { ProfileSettings } from "./components/ProfileSettings";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { RequestPasswordReset } from "./components/RequestPasswordReset";
import { ResetPassword } from "./components/ResetPassword";
import { SingleFactorProtectedRoute } from "./components/SingleFactorProtectedRoute";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Transfers } from "./components/Transfers";
import { toast } from "sonner";
import { MesoKitContext } from "@tigris/mesokit";
import { Scope } from "@sentry/react";
import {
  initializeTelemetry,
  isDarkMode,
  Posthog,
  Sentry,
} from "@tigris/common";

async function enableMocking() {
  if (import.meta.env.VITE_USE_MSW === "true") {
    const { worker } = await import("./mocks/browser");

    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return worker.start();
  }

  return;
}

/**
 * Sets the `.dark-mode` class on the `<html>` element.
 * This will be used as a signal to our CSS layer to render dark mode styles.
 */
isDarkMode((darkMode) => {
  if (darkMode) {
    document.documentElement.classList.add("dark-mode");
  } else {
    document.documentElement.classList.remove("dark-mode");
  }
});

initializeTelemetry({
  sentry: { useReactRouterV6BrowserTracingIntegration: true },
  posthog: { property: "account" },
});

const router = createBrowserRouter([
  {
    element: (
      // Provide telemetry library references to Mesokit components
      <MesoKitContext.Provider
        value={{ sentry: Sentry, toast, posthog: Posthog }}
      >
        <AppContextProvider>
          <AppLayout />
        </AppContextProvider>
      </MesoKitContext.Provider>
    ),
    children: [
      {
        path: AccountAppRoutes.ROOT,
        element: <LoginEmailPassword />,
      },
      {
        path: AccountAppRoutes.EMAIL_VERIFICATION,
        element: <EmailVerification />,
      },
      {
        path: AccountAppRoutes.RESET_PASSWORD,
        element: <ResetPassword />,
      },
      {
        path: AccountAppRoutes.REQUEST_PASSWORD_RESET,
        element: <RequestPasswordReset />,
      },
      {
        path: AccountAppRoutes.LOGIN_2FA,
        element: (
          <SingleFactorProtectedRoute>
            <Login2FA />
          </SingleFactorProtectedRoute>
        ),
      },
      {
        path: AccountAppRoutes.PROFILE_SETTINGS,
        element: (
          <ProtectedRoute>
            <ProfileSettings />
          </ProtectedRoute>
        ),
      },
      {
        path: AccountAppRoutes.TRANSFERS,
        element: (
          <ProtectedRoute>
            <Transfers />
          </ProtectedRoute>
        ),
      },
    ],
  },
  { path: "*", element: <NotFound /> },
]);

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById("app")!).render(
    <React.StrictMode>
      <Sentry.ErrorBoundary
        fallback={<p>An error has occurred</p>}
        beforeCapture={(scope: Scope) => scope.setLevel("warning")}
      >
        <RouterProvider router={router} />
      </Sentry.ErrorBoundary>
    </React.StrictMode>,
  );
});
