export enum AccountAppRoutes {
  ROOT = "/",
  LOGIN_2FA = "/login/2fa",
  PROFILE_SETTINGS = "/settings",
  TRANSFERS = "/transfers",
  EMAIL_VERIFICATION = "/email-verification",
  RESET_PASSWORD = "/settings/reset-password",
  REQUEST_PASSWORD_RESET = "/forgot-password",
}

/**
 * _Sometimes, you need to slow down and enjoy the scenery._
 *
 * This helper allows buffering of time to step out animations or transitions as needed.
 */
export const delay = (n = 500) => new Promise((r) => setTimeout(r, n));

/** The Meso partner ID. */
export const PARTNER_ID = "account-app";
