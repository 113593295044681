import { useCallback, useContext, useState } from "react";
import {
  AddPaymentCardForm,
  AddPaymentCardFormProps,
  MesoKitContext,
} from "@tigris/mesokit";
import { resolveAddPaymentCard } from "../api";

export type AddDebitCardFormProps = {
  onSuccess: () => void;
};

const TOAST_ID = "AddWalletForm";

export const AddDebitCardForm = ({ onSuccess }: AddDebitCardFormProps) => {
  const { toast } = useContext(MesoKitContext);
  // Start with `true` because unlike the transfer app, we aren't waiting for an animation to resolve.
  const [formEnabled, setFormEnabled] = useState(true);
  const handleTokenizationSuccess = useCallback<
    AddPaymentCardFormProps["onTokenizationSuccess"]
  >(
    async (token) => {
      setFormEnabled(false);

      const result = await resolveAddPaymentCard({ input: { token } });

      if (result.isOk()) {
        onSuccess();
      } else {
        toast?.error(result.error, { id: TOAST_ID });

        setFormEnabled(true);
      }
    },
    [onSuccess, toast],
  );

  return (
    <AddPaymentCardForm
      formEnabled={formEnabled}
      onTokenizationSuccess={handleTokenizationSuccess}
      formId="AddDebitCardForm"
    />
  );
};
