import { FocusEventHandler, useState } from "react";
import SmartySDK from "smartystreets-javascript-sdk";
import { Autocomplete } from "./Autocomplete";
import { ManualEntry } from "./ManualEntry";

export type Suggestion = SmartySDK.usAutocompletePro.Suggestion;
export type AddressInputMode = "autocomplete" | "manual";

export type AddressInputProps = {
  onAddressResolved: (
    suggestion: Suggestion | null,
    rawInputValue: string,
  ) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  labelText?: string;
  placeholder?: string;
  disabled: boolean;
  isValid: boolean;
  /**
   * The `name` for the `input` field. This will be matched by the `label`'s `htmlFor` props.
   */
  inputName: string;

  initialValue?: Suggestion;
  initialInputMode?: AddressInputMode;
};

export const AddressInput = (props: AddressInputProps) => {
  const [mode, setMode] = useState<AddressInputMode>(() => {
    if (props.initialValue || props.initialInputMode === "manual") {
      return "manual";
    }

    return "autocomplete";
  });
  const [suggestion, setSuggestion] = useState<Suggestion | null>(
    () => props.initialValue || null,
  );

  return (
    <>
      {mode === "autocomplete" ? (
        <Autocomplete
          {...props}
          setManualEntry={(suggestion?: Suggestion | null) => {
            setMode("manual");
            setSuggestion(suggestion ?? null);
          }}
        />
      ) : (
        <ManualEntry {...props} suggestion={suggestion} />
      )}
    </>
  );
};
