import { CustomToastError, InlineFormInput } from "@tigris/mesokit";
import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useState,
} from "react";
import { validateWalletAddress } from "../utils/validateWalletAddress";
import { resolveAddWallet } from "../api";
import { useAppContext } from "../hooks/useAppContext";
import { toast } from "sonner";
import { ErrorMessages } from "../utils/errorMessages";
import { Posthog, TelemetryEvents } from "@tigris/common";

const TOAST_ID = "AddWalletForm";
const FORM_ID = TOAST_ID;

export const AddWalletForm = ({
  onWalletAdded,
}: {
  onWalletAdded: () => void;
}) => {
  const { updateUser } = useAppContext();
  const [walletAddress, setWalletAddress] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();

      const validationResult = validateWalletAddress(walletAddress);

      if (validationResult.isOk()) {
        setIsValid(validationResult.value.valid);
        setIsLoading(true);

        const addWalletResult = await resolveAddWallet({
          input: { walletAddress },
        });

        if (addWalletResult.isErr()) {
          if (addWalletResult.error === ErrorMessages.INVALID_WALLET_ADDRESS) {
            toast.error(addWalletResult.error, { id: TOAST_ID });
            Posthog.capture(TelemetryEvents.formInputInvalid, {
              formId: FORM_ID,
            });
          } else {
            toast.error(<CustomToastError title="Failed to add wallet." />, {
              id: TOAST_ID,
            });
          }
          setIsLoading(false);
          setWalletAddress("");
          return;
        }

        updateUser(addWalletResult.value.user);
        onWalletAdded();
      } else {
        toast.error(validationResult.error, { id: TOAST_ID });
        setIsValid(false);
        setIsLoading(false);
        setWalletAddress("");
      }
    },
    [onWalletAdded, updateUser, walletAddress],
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      Posthog.capture(TelemetryEvents.formInputChange, { formId: FORM_ID });
      setWalletAddress(event.target.value);
      setIsValid(event.target.value.length > 0);
    },
    [],
  );

  return (
    <form onSubmit={handleSubmit} data-testid="AddWalletForm">
      <InlineFormInput
        input={{
          autoComplete: "off",
          autoFocus: true,
          disabled: isLoading,
          isValid,
          name: "addWallet_search",
          onChange: handleChange,
          placeholder: "New Wallet Address",
          type: "text",
          value: walletAddress,
          onFocus: () => {
            Posthog.capture(TelemetryEvents.formInputFocus, {
              formId: FORM_ID,
            });
          },
        }}
        button={{ primary: false, disabled: isLoading || !isValid }}
      />
    </form>
  );
};
