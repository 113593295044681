import { AddWalletForm } from "./AddWalletForm";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Title, Text, Button, TokenIcon } from "@tigris/mesokit";
import { WalletActionMenu } from "./WalletActionMenu";
import { formatDistance } from "date-fns";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { networkNamespaceToChain } from "../utils/networkNamespaceToChain";
import { useAppContext } from "../hooks/useAppContext";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useState } from "react";
import { Posthog, TelemetryEvents, truncateAddress } from "@tigris/common";
import { CurrencySymbol, NetworkNamespace } from "../types";

const walletInputAnimation = {
  initial: {
    opacity: 0,
    y: 10,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: { opacity: 0, y: 10 },
};

const walletItemAnimation = {
  initial: {
    opacity: 0,
    y: -24,
    height: "0px",
  },
  animate: {
    opacity: 1,
    y: 0,
    height: "auto",
  },
  exit: { opacity: 0 },
};

export const ProfileSettingsWallets = () => {
  const [showAddWalletForm, setShowAddWalletForm] = useState(false);
  const { session } = useAppContext();
  const isSmallScreen = useMediaQuery("only screen and (max-width : 450px)");

  const wallets =
    session?.user?.walletInstruments?.__typename === "WalletInstruments"
      ? session.user.walletInstruments.collection
      : [];

  return (
    <div className="flex flex-col gap-4" data-testid="ProfileSettingsWallets">
      <section>
        <Title.Medium className="font-bold">Wallets</Title.Medium>
        <Text>
          You can add as many wallets to your Meso account as you want.
        </Text>
      </section>
      {wallets.length === 0 ? (
        <section className="flex flex-col gap-4 rounded-2xl border p-6 text-center dark:border-neutral-700">
          <div>
            <Title.Medium className="font-bold">
              No Wallets Connected
            </Title.Medium>
            <Text>
              You can add a wallet here, or one will be added the next time you
              make a transfer.
            </Text>
          </div>
          {!showAddWalletForm ? (
            <motion.div key="link-wallet-button" {...walletInputAnimation}>
              <Button
                className="px-4 text-sm font-bold"
                containerClassName="block"
                primary={false}
                onClick={() => {
                  Posthog.capture(
                    TelemetryEvents.accountSettingsLinkWalletButtonClick,
                  );
                  setShowAddWalletForm(true);
                }}
              >
                Connect Wallet
              </Button>
            </motion.div>
          ) : (
            <motion.div
              className="mt-4"
              key="link-wallet-input"
              {...walletInputAnimation}
            >
              <AddWalletForm
                onWalletAdded={() => setShowAddWalletForm(false)}
              />
            </motion.div>
          )}
        </section>
      ) : (
        <section>
          <ul className="flex flex-col gap-2">
            {wallets.map((wallet) => {
              const chain = networkNamespaceToChain(wallet.networkNamespace);

              return (
                <AnimatePresence key={wallet.id} mode="wait">
                  <motion.li
                    key={`li-${wallet.id}`}
                    {...walletItemAnimation}
                    className="rounded-2xl border dark:border-neutral-700"
                  >
                    <div className="p-2 md:p-6" data-testid="Wallet">
                      <div className="flex items-center gap-2 text-neutral-800 dark:text-white">
                        <TokenIcon
                          className="h-6 w-6"
                          token={chain.currencySymbol}
                        />
                        <div className="flex flex-grow flex-col gap-0">
                          <div className="flex items-center gap-2">
                            <div
                              data-testid="Wallet:Address"
                              className="font-mono text-sm"
                            >
                              <Text className="whitespace-normal break-words font-mono md:text-base">
                                {isSmallScreen ||
                                chain.currencySymbol === CurrencySymbol.BTC
                                  ? truncateAddress(
                                      wallet.address,
                                      wallet.networkNamespace as NetworkNamespace,
                                    )
                                  : wallet.address}
                              </Text>
                            </div>
                            {wallet.verified && (
                              <FontAwesomeIcon
                                icon={icon({
                                  name: "badge-check",
                                  style: "solid",
                                })}
                                className="mr-2 scale-90 opacity-50 transition-transform group-hover:scale-100"
                              />
                            )}
                          </div>
                          <div className="flex items-center text-xs font-semibold tracking-tight">
                            <Text>{chain.name}</Text>
                            <Text className="px-0.5 md:px-1">{" • "}</Text>
                            <Text className="opacity-60">
                              Added{" "}
                              {formatDistance(
                                new Date(wallet.createdAt),
                                Date.now(),
                                { addSuffix: true },
                              )}
                            </Text>
                          </div>
                        </div>
                        <div data-testid="Wallet:ActionMenu">
                          <WalletActionMenu walletAddress={wallet.address} />
                        </div>
                      </div>
                    </div>
                  </motion.li>
                </AnimatePresence>
              );
            })}
          </ul>
          <AnimatePresence mode="wait">
            {!showAddWalletForm ? (
              <motion.div
                className="mt-4"
                key="link-wallet-button"
                {...walletInputAnimation}
              >
                <Button
                  className="px-4 text-sm font-bold"
                  containerClassName="block"
                  primary={false}
                  onClick={() => {
                    Posthog.capture(
                      TelemetryEvents.accountSettingsLinkWalletButtonClick,
                    );
                    setShowAddWalletForm(true);
                  }}
                >
                  Connect Wallet
                </Button>
              </motion.div>
            ) : (
              <motion.div
                className="mt-4"
                key="link-wallet-input"
                {...walletInputAnimation}
              >
                <AddWalletForm
                  onWalletAdded={() => {
                    setShowAddWalletForm(false);
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </section>
      )}
    </div>
  );
};
