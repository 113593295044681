import { UserFragment } from "./generated/sdk";

/** An authenticated user.  */
export type User = UserFragment;

/**
 * The root session data for the user's visit.
 */
export type Session = {
  id: string;
  token: string;
  riskSessionKey: string;
  user?: User;
};

export type ViemEthSigner = {
  // Per [viem signMessage](https://viem.sh/docs/actions/wallet/signMessage.html)
  signMessage(params: { message: string; account: string }): Promise<string>;
};

export type SolSigner = {
  // Per [Solana Wallet Standard](https://github.com/solana-labs/wallet-standard/blob/9d17ab038fb4c39fa08378571de40ea5ad593d46/packages/core/features/src/signMessage.ts#L47)
  // See example in [Ghost Wallet reference implementation](https://github.com/solana-labs/wallet-standard/blob/9d17ab038fb4c39fa08378571de40ea5ad593d46/packages/wallets/ghost/src/window.ts#L25) for example.
  signMessage(message: Uint8Array): Promise<
    | { signature: Uint8Array }
    // When signing with the Backpack app, `signMessage` returns a `Uint8Array` directly instead of the wrapped object
    | Uint8Array
  >;
};

export enum CurrencySymbol {
  ETH = "ETH",
  SOL = "SOL",
  BTC = "BTC",
  UNKNOWN = "",
}

// Export all types from `common` into this types module
export * from "@tigris/common/src/types";
