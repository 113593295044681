import * as Factory from "factory.ts";
import {
  FiatInstrumentFragment,
  InstrumentKind,
  CardType,
} from "../generated/sdk";
import { nanoid } from "nanoid";

export const fiatInstrumentFactory =
  Factory.Sync.makeFactory<FiatInstrumentFragment>({
    __typename: "FiatInstrument",
    id: Factory.each(() => `fi_${nanoid(8)}`),
    kind: InstrumentKind.PAYMENT_CARD,
    display: "2222",
    maskedNumber: "2222",
    cardType: CardType.DEBIT,
    cardScheme: "Visa",
    payoutEligible: true,
    createdAt: new Date("2024-02-26T19:21:11.038Z").toISOString(),
  });
