import { CurrencySymbol } from "../types";
import { WalletInstrumentFragment } from "../generated/sdk";

export const networkNamespaceToChain = (
  networkNamespace: WalletInstrumentFragment["networkNamespace"],
) => {
  if (networkNamespace.startsWith("eip155")) {
    return {
      currencySymbol: CurrencySymbol.ETH,
      chain: "ETH",
      name: "Ethereum",
    };
  }

  if (networkNamespace.startsWith("solana")) {
    return {
      currencySymbol: CurrencySymbol.SOL,
      chain: "SOL",
      name: "Solana",
    };
  }

  if (networkNamespace.startsWith("bip122")) {
    return {
      currencySymbol: CurrencySymbol.BTC,
      chain: "BTC",
      name: "Bitcoin",
    };
  }

  return {
    currencySymbol: CurrencySymbol.UNKNOWN,
    chain: "unknown",
    name: "unknown",
  };
};
