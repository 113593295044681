import { PublicKey } from "@solana/web3.js";
import { Result, err, ok } from "neverthrow";
import { Sentry } from "@tigris/common";

import { ErrorMessages } from "./errorMessages";

// via: https://github.com/wevm/viem/blob/c6e2b3635db9f3f28cf4d2af3f325d585b35f2c8/src/utils/address/isAddress.ts#L4C1-L5C1
const ethAddressRegex = /^0x[a-fA-F0-9]{40}$/;

export const validateWalletAddress = (
  address: string,
): Result<{ valid: boolean }, string> => {
  if (ethAddressRegex.test(address)) {
    return ok({ valid: true });
  }

  try {
    if (PublicKey.isOnCurve(new PublicKey(address))) {
      return ok({ valid: true });
    }
  } catch (error: unknown) {
    const nonBase58CharError =
      error instanceof Error && error.message.includes("Non-base58 character");
    if (!nonBase58CharError) {
      Sentry.captureException(error);
    }

    return err(ErrorMessages.GENERIC_ERROR);
  }

  return err(ErrorMessages.INVALID_WALLET_ADDRESS);
};
