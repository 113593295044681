import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { AccountAppRoutes } from "../utils/constants";
import { Session, User } from "../types";
import { resetSessionIdentifier } from "../api";
import { userFactory } from "../factories/User";
import { AppContext } from "./AppContext";
import { Posthog } from "@tigris/common";

export const AppContextProvider = ({ children }: PropsWithChildren) => {
  const [session, setSession] = useState<Session | undefined>(() => {
    if (import.meta.env.DEV && sessionStorage.getItem("autoLogin") === "true") {
      sessionStorage.setItem("autoLogin", "true");

      return {
        id: "stubs",
        token: "stub",
        riskSessionKey: "stub",
        user: userFactory.build(),
      };
    }

    return undefined;
  });
  const navigate = useNavigate();

  const logout = useCallback(
    (
      /** A valid query string (omitting the leading `?`) that will be appended to the root of the landing page. */
      loggedOutQueryParams?: string,
    ) => {
      // If there are query params, assume the session and sessionId will be reset in the landing page
      if (loggedOutQueryParams) {
        navigate(
          {
            pathname: AccountAppRoutes.ROOT,
            search: `?${loggedOutQueryParams}`,
          },
          { replace: true },
        );
      } else {
        setSession(undefined);
        resetSessionIdentifier();
      }
    },
    [navigate],
  );

  const updateUser = useCallback((user: User) => {
    setSession((previousSession) => {
      if (previousSession) {
        return { ...previousSession, user };
      }
    });
  }, []);

  useEffect(() => {
    // Once the user is "logged in" and we have a Meso ID, identify them in PostHog
    if (session?.user?.id) Posthog.identify(session.user.id);
  }, [session]);

  const contextValue = useMemo(
    () => ({ session, logout, updateUser, setSession }),
    [logout, session, updateUser, setSession],
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
